/**
 * ==============================
 * File System
 * ==============================
 */

import { Request } from './Request';

/**
 * A simple class wrapper for the File and FileReader web API, while this class
 * doesn't actually provide acces to the host file system, it does provide useful
 * utilities for form file inputs and remote content loading.
 *
 * @class
 */
export class FileSystem {


	/**
	 * @static readRemote - Read a file from a remote location given a URL. This
	 * function will fetch the file blob using the Request class and then use the
	 * read () function to read the blob in the format required.
	 *
	 * @param {type} url - URL to fetch the file from
	 * @param {type} [type = 'base64'] - Type of data to be read, values can be
	 * 'text', 'base64' and 'buffer'. This parameter is used for the read () function.
	 * @param {Object} [props = {}] - Props to send to the Request object
	 * @return {Promise<ArrayBuffer|string>} - Content of the file. The format
	 * depends on the type parameter used.
	 */
	static readRemote (url, type = 'base64', props = {}) {
		return Request.blob (url, {}, props).then ((file) => {
			return FileSystem.read (file, type);
		});
	}

	/**
	 * @static read - Read a given File or Blob object.
	 *
	 * @param  {File|Blob} file - File to read
	 * @param  {string} [type = 'text'] - Type of data to be read, values can be
	 * 'text', 'base64' and 'buffer'.
	 *
	 * @return {Promise<Event, ArrayBuffer|string>} - Promise that resolves to
	 * the Load event and content of the file. The format depends on the type
	 * parameter used.
	 */
	static read (file, type = 'text') {
		return new Promise ((resolve, reject) => {
			const reader = new FileReader ();

			reader.onload = (event) => {
				// Pass down the event object and the content
				resolve (event, event.target.result);
			};

			reader.onerror = (error) => {
				reject (error);
			};

			if (type === 'base64') {
				reader.readAsDataURL (file);
			} else if (type === 'buffer') {
				reader.readAsArrayBuffer (file);
			} else {
				reader.readAsText (file, 'UTF-8');
			}
		});
	}

	/**
	 * @static create - Create a new File, this uses the File API and will note
	 * actually create a file in the user's file system, however using it with
	 * other features, that may be possible
	 *
	 * @param  {string} file - Name of the file (Including extension)
	 * @param  {ArrayBuffer|ArrayBufferView|Blob|string} content - Content to save in the file
	 * @param  {string} [type = 'text/plain'] - Mime Type for the file
	 *
	 * @return {Promise<File>}
	 */
	static create (name, content, type = 'text/plain') {
		return Promise.resolve (new File ([content], name, {type}));
	}

	/**
	 * @static extension - Returns the extension of a file given its file name.
	 *
	 * @param  {string} name - Name or full path of the file
	 *
	 * @return {string} - File extension without the leading dot (.)
	 */
	static extension (name) {
		return name.split ('.').pop ();
	}

	/**
	 * @static isImage - Check if a file is an image by its extension.
	 *
	 * @param  {string} name - Name or full path of the file
	 *
	 * @return {boolean}
	 */
	static isImage (name) {
		const extensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'bmp'];
		return extensions.indexOf (FileSystem.extension (name).toLowerCase ()) > -1;
	}

}